<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xl="12">
        <b-card header="Información de la Característica">
          <b-form>
            <b-form-group
              label="Nombre"
              label-for="nombre"
              :label-cols="labelCols">
              <b-form-input 
                id="nombre"
                v-model.trim="$v.nombre.$model"
                :state="nombreValido"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="nombreFeedback">
                {{ nombreInvalidoMsj }}
              </b-form-invalid-feedback>          
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <app-a-b-m-button
      @save="save"
      @cancel="goBack">
    </app-a-b-m-button>  

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>
  </div>
</template>

<script>
import ABMButtons from '../shared/abmButtons.vue';
import { required, maxLength, numeric, decimal } from 'vuelidate/lib/validators';

import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "caracteristicaPropiedad/getField",
  mutationType: "caracteristicaPropiedad/updateField",
});

export default {
  name: 'CaracteristicasPropiedadesABM',
  components: {
    appABMButton: ABMButtons,
  },

  data () {
    return {
      noValidar: true,
      nombreValido: null,
      nombreInvalidoMsj: '',

      labelCols: 1
    }
  },
  computed: {
    ...mapFields([
	    'nombre',
    ]),
  },
  created() {
    let caracteristicaPropiedadId = '';
    if (this.$route.params){
      if (this.$route.params.id){
        caracteristicaPropiedadId = this.$route.params.id;
      }
    }

    this.$store.dispatch('caracteristicaPropiedad/getCaracteristicaPropiedad', caracteristicaPropiedadId);
  },
  methods: {
    validate(event) {
      if (event) {
        if (event.target) {
          /* Antes de intentar registrar por primera vez, no validamos */
          if (!this.noValidar) {
            this.$v.$touch();
            this.displayErrores(event.target.id);
          }
        }
      } 
    },
    displayErrores(field) {
      var validarTodos = false;
      var campo = '';

      if (field) {
        campo = field;
      } else {
        validarTodos = true;
      }

      if (campo == 'nombre' || validarTodos) {
        if (this.$v.nombre.$error) {
          this.nombreValido = false
          if (!this.$v.nombre.required) {
            this.nombreInvalidoMsj = 'Debe ingresar el nombre del tipo de propiedad';
          } else if (!this.$v.nombre.maxLength) {
            this.nombreInvalidoMsj = 'El nombre solo puede contener hasta 50 caracteres';
          }      
        } else {
          this.nombreValido = null;
          this.nombreInvalidoMsj = '';
        }
      }
    },
    save() {
      let save = true;

      this.noValidar = false;

      this.$v.$touch();

      if (this.$v.$anyError) {
        this.displayErrores();
      } else {
        if (save) {
          this.showSpinner();
          this.$store.dispatch('caracteristicaPropiedad/save')
            .then( () => this.showModalMsj ('Los cambios se registraron con éxito.', this.goBack, this.goBack, 'light'))
            .catch( error => this.showErrorMsj (error))
            .finally(() => this.hideSpinner());
        }
      }
    },
    goBack() {
      this.$router.push({ name: 'CaracteristicasPropiedades' });
    }
  },
  validations() {
    return {
      nombre: {
        required,
        maxLength: maxLength(50)
      },
    }
  }
}
</script>
